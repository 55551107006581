<template>
	<div class="account-con">
		<header class="header w c">
			<div class="contact-box">
				<img class="logo-img" @click="toPage('/')" :src="website.getWebsiteMsg.logo" alt="" />
			</div>
		</header>
		<div class="login-warp c w">
			<div class="content">
				<div class="form">
					<p class="title">账号申请</p>
					<el-form ref="ruleFormRef" class="z-form" :model="form" label-width="100px" :rules="rules">
						<el-form-item label="手机号" prop="mobile" size="large">
							<el-input v-model="form.mobile" placeholder="请输入手机号" />
						</el-form-item>
						<el-form-item label="真实姓名" prop="name" size="large">
							<el-input v-model="form.name" placeholder="请输入真实姓名" />
						</el-form-item>
						<el-form-item class="code" label="验证码" prop="checkCode" size="large">
							<el-input v-model="form.checkCode" placeholder="请输入验证码" />
							<el-button :class="{action:pwdCode.status}" type="primary" @click="sendCode(ruleFormRef)"
								:loading="pwdCode.loading">
								{{pwdCode.txt}}
							</el-button>
						</el-form-item>
						<el-form-item label="设置密码" prop="pwd" size="large">
							<el-input type="password" v-model="form.pwd" show-password placeholder="请输入至少8位数" />
						</el-form-item>
						<el-form-item label="确认密码" prop="checkPwd" size="large">
							<el-input type="password" v-model="form.checkPwd" placeholder="请再次输入密码" />
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm(ruleFormRef)" :loading="isClick">
								{{isClick?'注 册 中':'注 册'}}
							</el-button>
						</el-form-item>
					</el-form>
					<p class="tips">已有账号？<span @click="toPage('/account/login')">返回登录</span></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		sendCodePhone,
		register
	} from '@/api/user.js'
	import jsencrypt from '@/utils/jsencrypt'
	import {
		ElMessage
	} from 'element-plus'
	import {
		useRouter
	} from 'vue-router'
	import {
		reactive,
		ref,
		onBeforeUnmount,
	} from 'vue'
	import {
		useWebsite
	} from "@/pinia/index";

	const website = useWebsite()
	const router = useRouter()
	const ruleFormRef = ref('')
	const form = reactive({
		name: '',
		mobile: '',
		checkCode: '',
		pwd: '',
		checkPwd: ''
	})
	const pwdCode = reactive({
		status: 0,
		num: 60,
		txt: '发送验证码',
		time: null,
		loading: false,
	})
	let localNum = localStorage.getItem('zq-num')
	if (localNum && localNum > 0) {
		pwdCode.num = Number(localNum)
		sendCode()
	}
	async function sendCode(formEl) {
		if (!formEl) return
		await formEl.validateField('mobile', (valid, fields) => {
			if (valid) {
				if (pwdCode.status === 1) return false
				pwdCode.status = 1
				pwdCode.txt = '发送中'
				pwdCode.loading = true
				sendCodePhone(form.mobile).then(res => {
					pwdCode.loading = false
					ElMessage({
						message: '发送成功',
						type: 'success',
					})
					let time
					time = setInterval(() => {
						localStorage.setItem('zq-num', pwdCode.num)
						pwdCode.txt = `${pwdCode.num}S发送验证码`
						pwdCode.num--
						pwdCode.time = time
						if (pwdCode.num === 0) {
							clearInterval(pwdCode.time)
							pwdCode.status = 0
							pwdCode.num = 60
							pwdCode.txt = `发送验证码`
						}
					}, 1000)
				}).catch(e => {
					pwdCode.loading = false
					pwdCode.txt = '发送验证码'
					pwdCode.status = 0
				})
			}
		})

	}

	onBeforeUnmount(() => {
		pwdCode.status = 0
		localStorage.removeItem('zq-num')
		clearInterval(pwdCode.time)
	})
	const isClick = ref(false)
	const submitForm = async (formEl) => {
		if (!formEl) return
		await formEl.validate((valid, fields) => {
			if (valid) {
				if (!isClick.value) {
					isClick.value = true
					let formData = JSON.parse(JSON.stringify(form))
					formData.pwd = jsencrypt.encodeRSA(formData.pwd)
					delete formData.checkPwd
					register(formData).then(res => {
						ElMessage({
							message: '注册成功',
							type: 'success',
						})
						resetForm(formEl)
						isClick.value = false
						toPage('/account/login')
					}).catch(e => {
						isClick.value = false
					})
				}

			} else {

			}
		})
	}
	const resetForm = (formEl) => {
		if (!formEl) return
		formEl.resetFields()
	}
	const validateMobile = (rule, value, callback) => {
		const phoneReg = /^[1][3456789][0-9]{9}$/
		if (!phoneReg.test(value)) {
			callback(new Error('请输入正确的手机号'))
		} else {
			callback()
		}
	}
	const validateName = (rule, value, callback) => {
		let spaceReg = /\s/g
		if (spaceReg.test(value)) {
			callback(new Error('请输入正确真实姓名'))
		} else if (value === '') {
			callback(new Error('请输入真实姓名'))
		} else {
			callback()
		}
	}
	const validateCheckCode = (rule, value, callback) => {
		if (value === '') {
			callback(new Error('请输入验证码'))
		} else {
			callback()
		}
		callback()
	}
	const validatePass = (rule, value, callback) => {
		if (value === '') {
			callback(new Error('请设置密码'))
		} else if (value.length < 8) {
			callback(new Error("密码长度最少设置8位"))
		} else {
			callback()
		}
	}
	const validateCheckPass = (rule, value, callback) => {
		if (value != form.pwd) {
			callback(new Error('两次密码不一致'))
		} else if (value === '') {
			callback(new Error("请再次输入密码"))
		} else {
			callback()
		}
	}
	const rules = reactive({
		mobile: [{
			required: true,
			validator: validateMobile,
			trigger: 'blur'
		}],
		checkCode: [{
			required: true,
			validator: validateCheckCode,
			trigger: 'blur'
		}],
		name: [{
			required: true,
			validator: validateName,
			trigger: 'blur'
		}],
		pwd: [{
			required: true,
			validator: validatePass,
			trigger: 'blur'
		}],
		checkPwd: [{
			required: true,
			validator: validateCheckPass,
			trigger: 'blur'
		}],
	})

	function toPage(path) {
		router.push({
			path
		})
	}
</script>

<style lang="scss" scoped>
	.account-con {
		margin-top: - $headerHeight;
		position: relative;
		height: 100vh;
		width: 100vw;
		background: linear-gradient(to bottom, #ffffff 30%, #eed9c3);

		.header {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			em {
				font-style: normal;
				color: $themeColor;
				font-size: 16px;
				cursor: pointer;
			}

			.contact-box {
				position: relative;
				cursor: pointer;

				.logo-img {
					width: 250px;
				}
			}
		}

		.login-warp {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.content {
				width: 100%;
				background: url('../../assets/account/account-bg.png') no-repeat;
				background-size: 100% 100%;
				height: 540px;
				overflow: hidden;
				border-radius: 20px;
				border: 1px solid $themeColor;

				.form {
					float: right;
					width: 600px;
					background-color: #fff;
					height: 100%;
					padding: 20px 100px;

					.title {
						font-size: 26px;
					}

					em {
						font-style: normal;
						font-size: 12px;
						color: #AAA;
					}

					.z-form {
						margin-top: 35px;

						.code {
							.el-input {
								width: 170px;
								margin-right: 10px;
							}

							.el-button {
								width: 120px;
								border-radius: 3px;
							}

							.action {
								opacity: .7;
							}
						}

						.el-button {
							width: 100%;
							border-radius: 30px;
							height: 40px;
							font-size: 16px;
						}

						.login-btn {
							margin-top: 40px;

						}
					}

					.tips {
						padding-left: 170px;

						span {
							color: $themeColor;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>
